

















































import API from '@/plugins/axios';
import { instituteValueToKey } from '@/utils/utilsData';
import Vue from 'vue';
export default Vue.extend({
  name: 'Course',
  data: () => ({
    instituteValueToKey: { ...instituteValueToKey },
    data: {},
    course: {},
    id: '0',
    loading: true,
  }),
  computed: {
    goBack() {
      const search_term = localStorage.getItem('search_term');
      if (search_term) {
        return `/filters?search=${search_term}`;
      } else {
        return `/skills`;
      }
    },
    institute() {
      if (this.data) {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        const inst = (this.course as any).institute;
        return (this.instituteValueToKey as any)[inst];
        /* eslint-enable @typescript-eslint/no-explicit-any */
      } else {
        return '';
      }
    },
  },
  async mounted() {
    this.loading = true;
    this.id = this.$route.params.id as string;

    const { data } = await API.get(`/units/${this.id}/`);
    this.data = data;
    const { data: course } = await API.get(`/courses/${(this.data as { [key: string]: string }).course}/`);
    this.course = course;
    this.loading = false;
  },
});
