export const imageData = {
  improvement: {
    file: 'improvement',
    name: 'Improvement',
    image: '/skills/improvement.svg',
    imageNoBg: '/skillsNoBackground/improvement.svg',
    color: '#5BDEBC',
  },
  finance: {
    file: 'finance',
    name: 'Finance',
    image: '/skills/finance.svg',
    imageNoBg: '/skillsNoBackground/finance.svg',
    color: '#60D4F3',
  },
  engineering: {
    file: 'engineering',
    name: 'Engineering',
    image: '/skills/engineering.svg',
    imageNoBg: '/skillsNoBackground/engineering.svg',
    color: '#FD836B',
  },
  it: {
    file: 'it',
    name: 'IT',
    image: '/skills/it.svg',
    imageNoBg: '/skillsNoBackground/it.svg',
    color: '#B3A2F0',
  },
  crafts: {
    file: 'crafts',
    name: 'Crafts',
    image: '/skills/crafts.svg',
    imageNoBg: '/skillsNoBackground/crafts.svg',
    color: '#FCD370',
  },
  beauty: {
    file: 'beauty',
    name: 'Beauty',
    image: '/skills/beauty.svg',
    imageNoBg: '/skillsNoBackground/beauty.svg',
    color: '#F5D0B3',
  },
  farming: {
    file: 'farming',
    name: 'Farming',
    image: '/skills/farming.svg',
    imageNoBg: '/skillsNoBackground/farming.svg',
    color: '#9DD464',
  },
  en: {
    file: 'en',
    name: 'Language EN',
    image: '/skills/en.svg',
    imageNoBg: '/skillsNoBackground/en.svg',
    color: '#F397CF',
  },
  mt: {
    file: 'mt',
    name: 'Language MT',
    image: '/skills/mt.svg',
    imageNoBg: '/skillsNoBackground/mt.svg',
    color: '#F397CF',
  },
  misc: {
    file: 'misc',
    name: 'Misc',
    image: '/skills/misc.svg',
    imageNoBg: '/skillsNoBackground/misc.svg',
    color: '#ED5260',
  },
};

export const instituteKeyToValue = {
  ICA: 'Institute for Creative Arts',
  IAS: 'Institute of Applied Sciences',
  IET: 'Institute of Engineering and Transport',
  IBMC: 'Institute of Business Management and Commerce',
  ICS: 'Institute of Community Services',
  IICT: 'Institute of Information and Communication Technology',
};

export const instituteValueToKey = {
  'Institute for Creative Arts': 'ICA',
  'Institute of Applied Sciences': 'IAS',
  'Institute of Engineering and Transport': 'IET',
  'Institute of Business Management and Commerce': 'IBMC',
  'Institute of Community Services': 'ICS',
  'Institute of Information and Communication Technology': 'IICT',
};
